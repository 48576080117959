import { defaults } from 'axios';
export { setApiUrl, routes, getAssetsUrl };

var selectedApiUrl = 'default';

const apiUrlDev = 'https://localhost:7180/api/';
const apiUrlProd = 'https://api.lead-funnel.myc3.dev/api/';

const assetsUrlDev = 'https://assets.my-lead-funnel.de/';
const assetsUrlProd = 'https://assets.my-lead-funnel.de/';

function setApiUrl() {
    if (selectedApiUrl == 'dev') defaults.baseURL = apiUrlDev;
    else if (selectedApiUrl == 'prod') defaults.baseURL = apiUrlProd;
    else if (process.env.NODE_ENV == 'development') defaults.baseURL = apiUrlDev;
    else defaults.baseURL = apiUrlProd;
}

function getAssetsUrl() {
    if (selectedApiUrl == 'dev') return assetsUrlDev;
    else if (selectedApiUrl == 'prod') return assetsUrlProd;
    else if (process.env.NODE_ENV == 'development') return assetsUrlDev;
    else return assetsUrlProd;
}

const routes = [
    { name: 'NotFound', path: '/:pathMatch(.*)*', redirect: '/login', meta: { nav: false } },
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/LoginView.vue'),
        meta: { nav: false }
    },
    {
        name: 'Projekte',
        path: '/projekte',
        component: () => import('@/views/ProjekteView.vue'),
        meta: { nav: true }
    },
    {
        name: 'Editor',
        path: '/editor',
        component: () => import('@/views/EditorView.vue'),
        meta: { nav: true }
    },
    {
        name: 'Result',
        path: '/result',
        component: () => import('@/views/ErgebnisView.vue'),
        meta: { nav: true }
    }
];
